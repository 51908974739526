import { login, logout, getInfo } from "@/api/user";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { resetRouter } from "@/router";

const getDefaultState = () => {
  return {
    token: getToken(),
    username: "",
    permission: [],
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USERNAME: (state, username) => {
    state.username = username;
  },
  SET_PERMISSION: (state, permission) => {
    state.permission = permission;
  },
};

const actions = {
  // 登录
  login({ commit }, userInfo) {
    const { tell, smsNo, smsCode } = userInfo;
    return new Promise((resolve, reject) => {
      login({ tell: tell, smsNo: smsNo, smsCode: smsCode })
        .then((response) => {
          const { data } = response;
          commit("SET_TOKEN", data.token);
          setToken(data.token);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 获取用户信息
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then((response) => {
          const { data } = response;
          if (!data) {
            return reject("验证失败，请重新登录");
          }
          const { account, menus } = data;
          const permission = [];
          for (const menu of menus) {
            permission.push(menu.path);
          }

          commit("SET_PERMISSION", permission);
          commit("SET_USERNAME", account);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 退出登录
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          removeToken();
          resetRouter();
          commit("RESET_STATE");
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // 移除 token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeToken();
      commit("RESET_STATE");
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
