import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "./style/theme/index.css";
import "@/icons";
import "@/router/permission";
import SlideVerify from "vue-monoplasty-slide-verify";
import './style/index.css';
import *as filters from '@/filters/filters'
import Print from 'vue-print-nb'

// element-ui组件库 https://element.eleme.cn/#/zh-CN/component/loading

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(SlideVerify);
Vue.use(Print);

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
