/**
 * 校验链接
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

// 手机号
export function formatPh(ph) {
  if (!ph) {
    return "";
  }
  if (ph.indexOf("*") != -1) {
    return ph;
  }
  const reg = /(\d{3})\d{4}(\d{4})/;
  return ph.replace(reg, "$1****$2");
}

// 姓名
export function formatName(str) {
  if (null != str && str != undefined) {
    if (str.indexOf("*") != -1) {
      return str;
    }
    if (str.length == 2) {
      return str.substring(0, 1) + "*"; //截取name 字符串截取第一个字符，
    } else if (str.length == 3) {
      return str.substring(0, 1) + "*" + str.substring(2, 3); //截取第一个和第三个字符
    } else if (str.length > 3) {
      return str.substring(0, 1) + "*" + "*" + str.substring(3, str.length); //截取第一个和大于第4个字符
    }
  } else {
    return "";
  }
}
// 身份证
export function formatIdCard(card) {
  if (card.indexOf("*") != -1) {
    return card;
  }
  const reg = /(\d{4})\d+(\w{4})/;
  return card.replace(reg, "$1**********$2");
}
