// import Cookies from "js-cookie";

// token 存入 cookie 的 key
// const key = window._config["tokenCookieKey"];
const key = 'csias_token';

// 获取 token
export function getToken() {
  // return Cookies.get(key);
  return localStorage.getItem(key);
}

// 设置 token
export function setToken(token) {
  // return Cookies.set(key, token);
  return localStorage.setItem(key, token);
}

// 移除 token
export function removeToken() {
  // return Cookies.remove(key);
  return localStorage.removeItem(key);
}
