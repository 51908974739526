import router from "./index";
import store from "@/store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { getToken } from "@/utils/auth";
import getPageTitle from "@/utils/get-page-title";

// NProgress 配置
NProgress.configure({ showSpinner: false });

// 白名单
const whiteList = ["/login"];

// 路由切换之前
router.beforeEach(async (to, from, next) => {
  NProgress.start();

  document.title = getPageTitle(to.meta.title);

  const hasToken = getToken();

  if (hasToken) {
    if (to.path === "/login") {
      next({ path: "/" });
      NProgress.done();
    } else {
      const hasGetUserInfo = store.getters.username;
      if (hasGetUserInfo) {
        const permission = store.getters.permission;
        if (permission.indexOf(to.path) != -1 || to.path === "/404") {
          next();
        } else {
          next({ path: "/404" });
        }
      } else {
        try {
          const res = await store.dispatch("user/getInfo");
          // await store.dispatch("user/getInfo");
          const permission = store.getters.permission;
          if (permission.indexOf(to.path) != -1 || to.path === "/404") {
            next();
            // next({ path: res.menus[0].path });
          } else {
            next({ path: "/404" });
          }
          if (to.path == "/statistics") {
            next({ path: res.menus[0].path });
          }
        } catch (error) {
          await store.dispatch("user/resetToken");
          // Message.error(error || "错误");
          next(`/login?redirect=${to.path}`);
          NProgress.done();
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next(`/login?redirect=${to.path}`);
      NProgress.done();
    }
  }
});

// 路由切换之后
router.afterEach(() => {
  NProgress.done();
});
