import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true,
  },
  {
    path: "/404",
    component: () => import("@/views/404/index"),
    hidden: true,
  },
  {
    path: "/",
    component: Layout,
    redirect: "/statistics",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("@/views/dashboard/index"),
        meta: { title: "首页", icon: "" },
      },
      {
        path: "/statistics",
        name: "Statistics",
        component: () => import("@/views/statistics/index"),
        meta: { title: "数据统计", icon: "" },
      },

      {
        path: "/infoAq",
        component: () => import("@/views/infoAq/index"),
        redirect: "/infoAq/taskMgt",
        meta: { title: "信息采集", icon: "" },
        children: [
          {
            path: "/infoAq/taskMgt",
            name: "TaskMgt",
            component: () => import("@/views/infoAq/taskMgt/index"),
            meta: { title: "校拍计划管理", icon: "" },
          },
          {
            path: "/infoAq/orderMgt",
            name: "OrderMgt",
            component: () => import("@/views/infoAq/orderMgt/index"),
            meta: { title: "信息采集-校拍", icon: "" },
          },
          {
            path: "/infoAq/orderSpMgt",
            name: "OrderSpMgt",
            component: () => import("@/views/infoAq/orderSpMgt/index"),
            meta: { title: "信息采集-散拍", icon: "" },
          },
          {
            path: "/infoAq/failedOrders",
            name: "FailedOrders",
            component: () => import("@/views/infoAq/failedOrders/index"),
            meta: { title: "人脸识别订单", icon: "" },
          },
          {
            path: "/infoAq/deductionFailed",
            name: "deductionFailed",
            component: () => import("@/views/infoAq/deductionFailed/index"),
            meta: { title: "扣款失败订单", icon: "" },
          },
          {
            path: "/infoAq/batchReplaceMgt",
            name: "batchReplaceMgt",
            component: () => import("@/views/infoAq/batchReplaceMgt/index"),
            meta: { title: "批量替换照片-校拍", icon: "" },
          },
          {
            path: "/infoAq/batchReplaceSpMgt",
            name: "batchReplaceSpMgt",
            component: () => import("@/views/infoAq/batchReplaceSpMgt/index"),
            meta: { title: "批量替换照片-散拍", icon: "" },
          },
          {
            path: "/infoAq/NewOrderSpMgt",
            name: "NewOrderSpMgt",
            component: () => import("@/views/infoAq/NewOrderSpMgt/index"),
            meta: { title: "创建散拍任务", icon: "" },
          },
          {
            path: "/infoAq/orderLogs",
            name: "orderLogs",
            component: () => import("@/views/infoAq/orderLogs/index"),
            meta: { title: "支付日志信息", icon: "" },
          },
        ],
      },
      {
        path: "/ticket",
        component: () => import("@/views/ticket/index"),
        redirect: "/ticket/collection",
        meta: { title: "票据管理", icon: "" },
        children: [
          {
            path: "/ticket/collection",
            name: "Collection",
            component: () => import("@/views/ticket/collection/index"),
            meta: { title: "全部采集任务", icon: "" },
          },
          {
            path: "/ticket/notrefunded",
            name: "Notrefunded",
            component: () => import("@/views/ticket/notrefunded/index"),
            meta: { title: "未到款任务", icon: "" },
          },
          {
            path: "/ticket/outstanding",
            name: "Outstanding",
            component: () => import("@/views/ticket/outstanding/index"),
            meta: { title: "未确认到款任务", icon: "" },
          },
          {
            path: "/ticket/refund",
            name: "Refund",
            component: () => import("@/views/ticket/refund/index"),
            meta: { title: "返款管理", icon: "" },
          },
        ],
      },
      {
        path: "/bill",
        component: () => import("@/views/bill/index"),
        redirect: "/bill/reconciliation",
        meta: { title: "对账管理", icon: "" },
        children: [
          {
            path: "/bill/reconciliation",
            name: "Reconciliation",
            component: () => import("@/views/bill/reconciliation/index"),
            meta: { title: "工行支付", icon: "" },
          },
          {
            path: "/bill/wxreconciliation",
            name: "wxReconciliation",
            component: () => import("@/views/bill/wxreconciliation/index"),
            meta: { title: "备用支付", icon: "" },
          },
          {
            path: "/bill/epay",
            name: "BillEpay",
            component: () => import("@/views/bill/epay/index"),
            meta: { title: "E钱包用户查询", icon: "" },
          },
        ],
      },
      {
        path: "/photoReview",
        component: () => import("@/views/photoReview/index"),
        redirect: "/photoReview/cjTask",
        meta: { title: "照片审核管理", icon: "" },
        children: [
          {
            path: "/photoReview/cjTask",
            name: "reviewCjTask",
            component: () => import("@/views/photoReview/cjTask/index"),
            meta: { title: "采集任务列表", icon: "" },
          },
          {
            path: "/photoReview/statistics",
            name: "reviewStatistics",
            component: () => import("@/views/photoReview/statistics/index"),
            meta: { title: "审核统计", icon: "" },
          },
        ],
      },
      {
        path: "/batch",
        component: () => import("@/views/batch/index"),
        redirect: "/batch/orderSpMgt",
        meta: { title: "上传批次管理", icon: "" },
        children: [
          {
            path: "/batch/orderMgt",
            name: "batchOrderMgt",
            component: () => import("@/views/batch/orderMgt/index"),
            meta: { title: "创建上传批次", icon: "" },
          },
          {
            path: "/batch/batchList",
            name: "batchList",
            component: () => import("@/views/batch/batchList/index"),
            meta: { title: "全部上传批次", icon: "" },
          },
        ],
      },
      {
        path: "/mail",
        component: () => import("@/views/mail/index"),
        redirect: "/mail/tobemailed",
        meta: { title: "邮寄管理", icon: "" },
        children: [
          {
            path: "/mail/tobemailed",
            name: "tobemailed",
            component: () => import("@/views/mail/tobemailed/index"),
            meta: { title: "待邮寄批次", icon: "" },
          },
          {
            path: "/mail/tobeprinted",
            name: "tobeprinted",
            component: () => import("@/views/mail/tobeprinted/index"),
            meta: { title: "待打印电子面单", icon: "" },
          },
          {
            path: "/mail/tobeconfirmed",
            name: "tobeconfirmed",
            component: () => import("@/views/mail/tobeconfirmed/index"),
            meta: { title: "待确认邮寄批次", icon: "" },
          },
          {
            path: "/mail/mailed",
            name: "mailMailed",
            component: () => import("@/views/mail/mailed/index"),
            meta: { title: "已邮寄批次", icon: "" },
          },
        ],
      },
      {
        path: "/contrast",
        component: () => import("@/views/contrast/index"),
        redirect: "/contrast/XPcontrast",
        meta: { title: "图像对比", icon: "" },
        children: [
          {
            path: "/contrast/XPcontrast",
            name: "XPcontrast",
            component: () => import("@/views/contrast/XPcontrast/index"),
            meta: { title: "校拍对比服务", icon: "" },
          },
          {
            path: "/contrast/contrastConfigure",
            name: "contrastConfigure",
            component: () => import("@/views/contrast/contrastConfigure/index"),
            meta: { title: "对比配置", icon: "" },
          },
        ],
      },
      {
        path: "/schoolMgt",
        component: () => import("@/views/schoolMgt/index"),
        redirect: "/schoolMgt/schoolInfoMgt",
        meta: { title: "学校管理", icon: "" },
        children: [
          {
            path: "/schoolMgt/schoolInfoMgt",
            name: "SchoolInfoMgt",
            component: () => import("@/views/schoolMgt/schoolInfoMgt/index"),
            meta: { title: "学校信息管理", icon: "" },
          },
          {
            path: "/schoolMgt/authorization",
            name: "authorization",
            component: () => import("@/views/schoolMgt/authorization/index"),
            meta: { title: "采集授权配置", icon: "" },
          },
        ],
      },
      {
        path: "/studentMgt",
        component: () => import("@/views/studentMgt/index"),
        redirect: "/studentMgt/studentInfoMgt",
        meta: { title: "学生管理", icon: "" },
        children: [
          {
            path: "/studentMgt/studentInfoMgt",
            name: "studentInfoMgt",
            component: () => import("@/views/studentMgt/studentInfoMgt/index"),
            meta: { title: "学生信息管理", icon: "" },
          },
          {
            path: "/administration",
            name: "administration",
            component: () => import("@/views/administration/index"),
            meta: { title: "异常学籍管理", icon: "" },
          },
          {
            path: "/studentMgt/orderList",
            name: "sOrderList",
            component: () => import("@/views/studentMgt/orderList/index"),
            meta: { title: "支付订单查询", icon: "" },
          },
        ],
      },
      {
        path: "/addServicesMgt",
        component: () => import("@/views/addServicesMgt/index"),
        redirect: "/addServicesMgt/orderTruingMgt",
        meta: { title: "附加服务", icon: "" },
        children: [
          {
            path: "/addServicesMgt/orderTruingMgt",
            name: "OrderTruingMgt",
            component: () =>
              import("@/views/addServicesMgt/orderTruingMgt/index"),
            meta: { title: "现场精修管理", icon: "" },
          },
        ],
      },
      {
        path: "/incrementMgt",
        component: () => import("@/views/increment/index"),
        redirect: "/increment/order",
        meta: { title: "增值服务管理", icon: "" },
        children: [
          {
            path: "/increment/order",
            name: "incrementOrder",
            component: () => import("@/views/increment/order/index"),
            meta: { title: "订单管理", icon: "" },
          },
          {
            path: "/increment/collection",
            name: "incrementCollection",
            component: () => import("@/views/increment/collection/index"),
            meta: { title: "采集员管理", icon: "" },
          },
          {
            path: "/increment/business",
            name: "incrementBusiness",
            component: () => import("@/views/increment/business/index"),
            meta: { title: "增值业务管理", icon: "" },
          },
        ],
      },
      {
        path: "/incrementNew",
        component: () => import("@/views/incrementNew/index"),
        redirect: "/incrementNew/orderList",
        meta: { title: "增值业务管理", icon: "" },
        children: [
          {
            path: "/incrementNew/orderList",
            name: "incrementNewOrderList",
            component: () => import("@/views/incrementNew/orderList/index"),
            meta: { title: "订单管理", icon: "" },
          },
          {
            path: "/incrementNew/unconfigure",
            name: "incrementNewUnconfigure",
            component: () => import("@/views/incrementNew/unconfigure/index"),
            meta: { title: "取消原因配置", icon: "" },
          },
          {
            path: "/incrementNew/statistics",
            name: "incrementNewStatistics",
            component: () => import("@/views/incrementNew/statistics/index"),
            meta: { title: "增值业务统计", icon: "" },
          },
        ],
      },
      {
        path: "/compareServe/taskList",
        name: "compareServeTaskList",
        component: () => import("@/views/compareServe/taskList/index"),
        meta: { title: "比对任务管理", icon: "" },
      },
      {
        path: "/sysMgt",
        component: () => import("@/views/sysMgt/index"),
        redirect: "/sysMgt/roleMgt",
        meta: { title: "系统管理", icon: "" },
        children: [
          {
            path: "/sysMgt/orgMgt",
            name: "OrgMgt",
            component: () => import("@/views/sysMgt/orgMgt/index"),
            meta: { title: "组织机构管理", icon: "" },
          },
          {
            path: "/sysMgt/deptMgt",
            name: "DeptMgt",
            component: () => import("@/views/sysMgt/deptMgt/index"),
            meta: { title: "部门管理", icon: "" },
          },
          {
            path: "/sysMgt/roleMgt",
            name: "RoleMgt",
            component: () => import("@/views/sysMgt/roleMgt/index"),
            meta: { title: "角色管理", icon: "" },
          },
          {
            path: "/sysMgt/permissionMgt",
            name: "PermissionMgt",
            component: () => import("@/views/sysMgt/permissionMgt/index"),
            meta: { title: "权限管理", icon: "" },
          },
          {
            path: "/sysMgt/userMgt",
            name: "UserMgt",
            component: () => import("@/views/sysMgt/userMgt/index"),
            meta: { title: "用户管理", icon: "" },
          },
          {
            path: "/sysMgt/logQuery",
            name: "LogQuery",
            component: () => import("@/views/sysMgt/logQuery/index"),
            meta: { title: "日志查询", icon: "" },
          },
          {
            path: "/sysMgt/paramSet",
            name: "ParamSet",
            component: () => import("@/views/sysMgt/paramSet/index"),
            meta: { title: "参数设置", icon: "" },
          },
          {
            path: "/sysMgt/smsSignature",
            name: "SmsSignature",
            component: () => import("@/views/sysMgt/smsSignature/index"),
            meta: { title: "短信签名配置", icon: "" },
          },
          {
            path: "/sysMgt/smsTemplate",
            name: "SmsTemplate",
            component: () => import("@/views/sysMgt/smsTemplate/index"),
            meta: { title: "短信模板配置", icon: "" },
          },
        ],
      },
      {
        path: "/encryption",
        component: () => import("@/views/encryption/index"),
        redirect: "/encryption/taskMG",
        meta: { title: "加密采集管理", icon: "" },
        children: [
          {
            path: "/encryption/taskMG",
            name: "JMTaskMG",
            component: () => import("@/views/encryption/taskMG/index"),
            meta: { title: "采集任务管理", icon: "" },
          },

          {
            path: "/encryption/orderXP",
            name: "JMOrderXP",
            component: () => import("@/views/encryption/orderXP/index"),
            meta: { title: "订单列表（校拍）", icon: "" },
          },

          {
            path: "/encryption/orderSP",
            name: "JMOrderSP",
            component: () => import("@/views/encryption/orderSP/index"),
            meta: { title: "订单列表（散拍）", icon: "" },
          },
          {
            path: "/encryption/onlineCollection",
            name: "onlineCollection",
            component: () =>
              import("@/views/encryption/onlineCollection/index"),
            meta: { title: "订单列表（线上）", icon: "" },
          },
        ],
      },
      {
        path: "/informationMg",
        component: () => import("@/views/informationMg/index"),
        redirect: "/informationMg/columnlist",
        meta: { title: "信息管理", icon: "" },
        children: [
          {
            path: "/informationMg/columnlist",
            name: "columnlist",
            component: () => import("@/views/informationMg/columnlist/index"),
            meta: { title: "栏目管理", icon: "" },
          },
          {
            path: "/informationMg/newsList",
            name: "newsList",
            component: () => import("@/views/informationMg/newsList/index"),
            meta: { title: "信息列表", icon: "" },
          },
        ],
      },
    ],
  },
  {
    path: "*",
    redirect: "/404",
    hidden: true,
  },
];
// // 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

const createRouter = () =>
  new VueRouter({
    // mode: "history",
    base: process.env.BASE_URL,
    routes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

export default router;
