<template>
  <!-- <el-scrollbar
    wrap-style="overflow-x: hidden; height: calc(100vh - 60px);"
    view-style="height: 100%;"
    class="scrollbar"
  > -->
  <div class="scrollbar">
    <el-menu
      class="menu"
      :default-active="activeMenu"
      :router="true"
      background-color="#303133"
      text-color="#fff"
      active-text-color="#446cf3"
    >
      <MenuItem v-for="menu in menus" :key="menu.path" :item="menu" />
    </el-menu>
  </div>
  <!-- </el-scrollbar> -->
</template>

<script>
import MenuItem from "./MenuItem";

export default {
  name: "Menu",
  components: { MenuItem },
  computed: {
    menus() {
      for (const item of this.$router.options.routes) {
        // console.log(item);
        if (item.path === "/") {
          return item.children;
        }
      }
      return [];
    },
    activeMenu() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped>
.scrollbar {
  /* height: 100%; */
  height: calc(100vh - 60px);
  background-color: rgb(48, 49, 51);
  overflow: hidden;
  overflow-y: auto;
}
.scrollbar::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.scrollbar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}
.scrollbar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}

.menu {
  /* height: 100%; */
}
</style>
