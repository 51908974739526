<template>
  <div v-if="hasPermission()">
    <el-menu-item v-if="!hasChildren()" :index="item.path">
      <i v-if="isElIcon()" :class="item.meta.icon" />
      <!-- <svg-icon v-else :icon-class="item.meta.icon" /> -->

      <span slot="title">{{ item.meta.title }}</span>
    </el-menu-item>
    <el-submenu v-else :index="item.path">
      <template slot="title">
        <i v-if="isElIcon()" :class="item.meta.icon" />
        <!-- <svg-icon v-else :icon-class="item.meta.icon" /> -->

        <span slot="title">{{ item.meta.title }}</span>
      </template>
      <MenuItem
        v-for="child in item.children"
        :key="child.path"
        :item="child"
      />
    </el-submenu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MenuItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["permission"]),
  },
  methods: {
    hasPermission() {
      if (this.permission.indexOf(this.item.path) != -1) {
        return true;
      }
      return false;
    },
    hasChildren() {
      if (this.item.children) {
        return true;
      }
      return false;
    },
    isElIcon() {
      if (this.item.meta.icon.includes("el-icon")) {
        return true;
      }
      return false;
    },
  },
};
</script>
