<template>
  <el-container class="container">
    <el-header class="header">
      <div class="leftHeader">
        <img class="csias-icon" src="@/assets/img/csias-icon.png" alt="csias-icon" />
        <span>高校学生图像采集管理系统</span>
        <!-- <a
          href="http://yntc.ynqmxx.cn/yngxbys/admin/login.html"
          target="_blank"
          class="link"
          >图像采集管理一</a
        >
        <a
          href="http://oldyntc.ynqmxx.cn/yngxbys/admin/login.html"
          target="_blank"
          class="link"
          >图像采集管理二</a
        > -->
      </div>
      <!--      <div class="leftHeaderTest">-->
      <!--        <img-->
      <!--                class="csias-icon"-->
      <!--                src="@/assets/img/csias-icon.png"-->
      <!--                alt="csias-icon"-->
      <!--        />-->
      <!--        <span>高校学生图像采集管理系统&#45;&#45;测试</span>-->
      <!--      </div>-->
      <div class="rightHeader">
        <el-dropdown trigger="click" @command="handleCommand">
          <span class="el-dropdown-link username">
            {{ username }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="updatePassword">修改密码</el-dropdown-item>
            <el-dropdown-item command="logout" divided @click="logout"
              >退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container>
      <el-aside class="aside" :width="'200'">
        <Menu />
      </el-aside>
      <el-main class="main">
        <div class="breadcrumb">
          <Breadcrumb />
        </div>
        <div class="appMain">
          <transition name="appMain" mode="out-in">
            <router-view />
          </transition>
        </div>
      </el-main>
    </el-container>

    <el-dialog title="修改密码" :visible.sync="updatePasswordDialogVisible" width="500px">
      <el-form
        ref="updatePasswordForm"
        :model="updatePasswordForm"
        :rules="updatePasswordRules"
        label-width="100px"
      >
        <el-form-item label="原密码" prop="password">
          <el-input
            type="password"
            v-model="updatePasswordForm.password"
            placeholder="请输入原密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            type="password"
            v-model="updatePasswordForm.newPassword"
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="confirmNewPassword">
          <el-input
            type="password"
            v-model="updatePasswordForm.confirmNewPassword"
            placeholder="请确认新密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="updatePasswordDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="handleUpdatePassword"
          :loading="updatePasswordLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import { mapGetters } from "vuex";
import Menu from "@/components/Menu";
import Breadcrumb from "@/components/Breadcrumb";
import { updatePassword } from "@/api/user";

export default {
  name: "Layout",
  components: {
    Menu,
    Breadcrumb,
  },
  data() {
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error("原密码不能为空"));
      } else {
        callback();
      }
    };
    const validateNewPassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error("新密码不能为空"));
      } else {
        callback();
      }
    };
    const validateConfirmNewPassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error("确认新密码不能为空"));
      } else {
        if (value !== this.updatePasswordForm.newPassword) {
          callback(new Error("确认新密码与新密码并不相符"));
        } else {
          callback();
        }
      }
    };
    return {
      updatePasswordDialogVisible: false,
      updatePasswordForm: {},
      updatePasswordRules: {
        password: [{ required: true, trigger: "blur", validator: validatePassword }],
        newPassword: [
          { required: true, trigger: "blur", validator: validateNewPassword },
        ],
        confirmNewPassword: [
          {
            required: true,
            trigger: "blur",
            validator: validateConfirmNewPassword,
          },
        ],
      },
      updatePasswordLoading: false,
    };
  },
  computed: {
    ...mapGetters(["username"]),
  },
  methods: {
    handleCommand(command) {
      if (command === "updatePassword") {
        this.updatePassword();
      } else if (command === "logout") {
        this.logout();
      }
    },
    updatePassword() {
      this.updatePasswordForm = {};
      if (this.$refs.updatePasswordForm !== undefined) {
        this.$refs.updatePasswordForm.resetFields();
      }
      this.updatePasswordDialogVisible = true;
    },
    handleUpdatePassword() {
      this.$refs.updatePasswordForm.validate((valid) => {
        if (valid) {
          this.updatePasswordLoading = true;
          const data = this.updatePasswordForm;

          updatePassword(data)
            .then((resp) => {
              this.$notify({
                title: "修改密码成功",
                message: resp.message,
                type: "success",
              });
              this.updatePasswordLoading = false;
              this.updatePasswordDialogVisible = false;
            })
            .catch((error) => {
              this.updatePasswordLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
  },
};
</script>

<style>
.container {
  height: 100%;
}

.fn-14 {
  font-size: 14px;
}

.header {
  background-color: #446cf3;
  display: flex;
  justify-content: space-between;
  line-height: 60px;
  font-size: 20px;
  color: #ffffff;
}

.leftHeader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftHeader a.link {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  font-size: 14px;
  margin-left: 15px;
}
.leftHeaderTest {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffb800;
}

.csias-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.rightHeader {
  display: flex;
}

.username {
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
}

.aside {
  width: 200px;
}

.main {
  padding: 0 !important;
}

.breadcrumb {
  border-bottom: 1px solid #ebeef5;
  padding: 20px;
  height: 14px;
}

.appMain {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%) inset;
  background-color: #f2f6fc;
  height: calc(100% - 55px - 20px);
  padding: 15px;
  overflow-x: hidden;
}

.appMain-leave-active,
.appMain-enter-active {
  transition: all 0.5s;
}

.appMain-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.appMain-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.el-table td {
  padding: 8px 0 !important;
}

.el-table th {
  text-align: center !important;
}

.el-table td .el-button--text {
  padding: 5px 0px !important;
}

.breadcrumb {
  padding: 15px 20px !important;
}

.el-input--prefix .el-input__inner {
  padding-right: 10px !important;
}

.el-dialog--center .el-dialog__body {
  padding: 15px !important;
}

.el-dialog__header {
  padding: 10px 15px !important;
}
</style>
