import axios from "axios";
import {
  Message
} from "element-ui";
import store from "@/store";
import {
  getToken
} from "@/utils/auth";

// 创建 axios 对象
const service = axios.create({
  // baseURL: window._config["baseUrl"],
  baseURL: '/api/csias',
  timeout: 180000,
  withCredentials: true,
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    //config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    // config.headers["Cache-Control"] = "no-cache";
    // config.headers["X-Requested-With"] = "XMLHttpRequest";
    // config.headers["Access-Control-Allow-Methods"] = "*";
    // config.headers["Access-Control-Allow-Origin"] = "*";
    // config.headers["Content-Type"] = "application/json;charset=utf-8";
    if (store.getters.token) {
      config.headers["Authorization"] = "Bearer " + getToken();
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;

    if (res.code !== "0") {
      Message({
        message: res.message || "错误",
        type: "error",
        duration: 5 * 1000,
      });

      if (res.code === "5003") {
        setTimeout(function () {
          store.dispatch("user/resetToken").then(() => {
            location.reload();
          });
        }, 1000);
      }
      return Promise.reject(new Error(res.message || "错误"));
    } else {
      return res;
    }
  },
  (error) => {
    Message({
      message: error.response.data.message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;