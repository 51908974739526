// 页面标题
// const title = window._config["pageTitle"];
const title = '高校学生图像采集管理系统';

// 获取页面标题
export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${title}`;
  }
  return `${title}`;
}
