import request from "@/utils/request";

export function login(data) {
  return request({
    url: "/auth/login",
    method: "post",
    data,
  });
}

export function getInfo() {
  return request({
    url: "/auth/getInfo",
    method: "get",
  });
}

export function logout() {
  return request({
    url: "/auth/logout",
    method: "get",
  });
}

export function updateInfo(data) {
  return request({
    url: "/auth/updateInfo",
    method: "post",
    data,
  });
}

export function updatePassword(data) {
  return request({
    url: "/auth/updatePassword",
    method: "post",
    data,
  });
}

export function queryUser(page, size, data) {
  return request({
    url: "/sys/sysUser/queryUser?page=" + page + "&size=" + size,
    method: "post",
    data,
  });
}

export function addUser(data) {
  return request({
    url: "/sys/sysUser/addUser",
    method: "post",
    data,
  });
}

export function getUser(account) {
  return request({
    url: "/sys/sysUser/getUser/" + account,
    method: "get",
  });
}

export function updateUser(data) {
  return request({
    url: "/sys/sysUser/updateUser",
    method: "post",
    data,
  });
}

export function resetPassword(data) {
  return request({
    url: "/sys/sysUser/resetPassword",
    method: "post",
    data,
  });
}

export function disableUser(data) {
  return request({
    url: "/sys/sysUser/disableUser",
    method: "post",
    data,
  });
}

export function enableUser(data) {
  return request({
    url: "/sys/sysUser/enableUser",
    method: "post",
    data,
  });
}

export function deleteUser(data) {
  return request({
    url: "/sys/sysUser/deleteUser",
    method: "post",
    data,
  });
}

export function sendSmsCode(data) {
  return request({
    url: "/auth/sendSmsCode/" + data,
    method: "get",
  });
}
